import { Component, Vue } from "vue-property-decorator";

import { Header,Footer } from "@/layout";
import { QuizModel } from "@/models";
import { TeCommon } from "../Common";

export class SearchForm {
  grade = '';
  cid = '';
  sid = '';
  clid = '';
  student = '';
  date = '';
  name = '';
}

type response = { [key: string]: unknown };

@Component<ApTeacherHomeworkResultController>({
    components: {
        Header,Footer
    }
})
export default class ApTeacherHomeworkResultController extends Vue {
    private searchForm = new SearchForm();
    private cityList: object = {};
    private schoolList: object = {};
    private classList: object = {};
    private resultList: object = {};
    private gradeList: object = {};
    private num = 0;
    private page = 1;
    private perpage = 50;
    private maxPage = 0;
    private tid = window.localStorage.getItem('teacher_tid') as string;
    private token = window.localStorage.getItem('teacherToken') as string;

    public async created() {
        this.getResultList();
        this.cityList = await TeCommon.getTECity(this.tid);   //取得該老師所屬班級的所有縣市
        this.gradeList = await TeCommon.getGrade();
    }

    //取得該老師所屬班級之該縣市分校列表
    public async getTESchool(){
        this.searchForm.sid = '';
        this.searchForm.clid = '';
        const cid = this.searchForm.cid;
        this.schoolList = await TeCommon.getTESchool(cid, this.tid);
        this.classList = [];
    }

    // 取得該老師所屬班級之該分校班級列表
    public async getTEClass(){ 
        this.searchForm.clid = '';
        const sid = this.searchForm.sid;
        this.classList = await TeCommon.getTEClass(sid, this.tid);
    }

    public async getResultList(){
        const item = {
            page: this.page,
            perpage: this.perpage,
            grade : this.searchForm.grade,
            cid : this.searchForm.cid,
            sid : this.searchForm.sid,
            clid : this.searchForm.clid,
            student : this.searchForm.student,
            date : this.searchForm.date,
            name : this.searchForm.name,
            tid : this.tid,
            token: this.token,
        }
        const data = await QuizModel.teGetResultList(item) as response;
        TeCommon.checkToken(data.ERR_CODE as number);
        this.num = data.num as number;
        this.resultList = data.list as object;
        this.maxPage = Math.ceil(this.num/this.perpage);
    }
    
    private getPageList(page: number) {
        this.page = (page < 1) ? 1 : (page > this.maxPage) ?  this.maxPage : page;
        this.getResultList();
    }
}